/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.65.19/lib/codemirror.min.js
 * - /npm/codemirror@5.65.19/mode/htmlmixed/htmlmixed.min.js
 * - /npm/codemirror@5.65.19/mode/xml/xml.min.js
 * - /npm/codemirror@5.65.19/mode/css/css.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
